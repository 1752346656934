import moment from 'moment';
import { Calendar } from 'primereact/calendar';
import { ColumnEditorOptions } from 'primereact/column';

function DateEditor(options: ColumnEditorOptions): JSX.Element {
  const { value, editorCallback } = options;

  return (
    <Calendar
      value={value}
      showIcon
      dateFormat="dd/mm/yy"
      onChange={(e) => {
        editorCallback?.(moment(e.value?.toString()).format());
      }}
    />
  );
}

export default DateEditor;
