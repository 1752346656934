import { createApi } from '@reduxjs/toolkit/dist/query/react';
import moment from 'moment';
import CategoryModel from 'src/models/category';
import EmployeeModel from 'src/models/employee';
import SalaryModel from 'src/models/salary';
import SubmissionModel from 'src/models/submission';
import SubmissionItemModel from 'src/models/submissionItem';
import CookieService from 'src/services/cookie';
import { axiosBaseQuery } from '../utils/axios';

export interface IPagination {
  current: number,
  total: number,
  size: number,
}
interface ResponseWithPagination<T> {
  data: T,
  meta: {
    pagination: IPagination
  }
}

export interface IPaginationRequest {
  page: number,
  size: number,
  id?: string,
  search?: string,
}

export interface LoginRequest {
  email: string,
  password: string,
}

export interface LoginResponse {
  data: {
    token: string,
    user: EmployeeModel,
  }
}

export const service = createApi({
  reducerPath: 'cafetariaplan-api',
  baseQuery: axiosBaseQuery({ baseUrl: process.env.REACT_APP_API_BASE_URL || '/api/v1' }),
  tagTypes: ['auth', 'users', 'salaries', 'submissions', 'categories', 'submissionItems', 'statistics'],
  endpoints: (build) => ({
    // Auth
    login: build.mutation<LoginResponse, LoginRequest>({
      query: (credentials) => ({
        url: 'auth',
        method: 'POST',
        data: credentials,
      }),
      invalidatesTags: ['auth', 'users'],
    }),
    logout: build.mutation({
      query: () => ({
        url: 'auth/logout',
        method: 'DELETE',
      }),
      invalidatesTags: ['auth'],
      async onQueryStarted(id, { queryFulfilled }) {
        await queryFulfilled;
        CookieService().remove('token');
      },
    }),
    getAuthUser: build.query<EmployeeModel, void>({
      query: () => ({
        url: 'auth',
        method: 'GET',
      }),
      providesTags: ['auth'],
      async onQueryStarted(id, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err) {
          CookieService().remove('token');
        }
      },
    }),
    createEmployee: build.mutation({
      query: (data) => ({
        url: 'users',
        method: 'POST',
        data: {
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          employeeNumber: data.employeeNumber,
          startedAt: moment(data.startedAt).startOf('day').format(),
          remainingWorkDays: data.remainingWorkDays,
          salary: {
            amount: data.salary,
            activeAt: moment(data.startedAt).startOf('day').format(),
          },
        },
      }),
      invalidatesTags: ['users'],
    }),
    getEmployees: build.query<ResponseWithPagination<EmployeeModel[]>, IPaginationRequest>({
      query: ({ page, size, search }) => ({
        url: `users?page=${page}&size=${size}&filter[search]=${search}&filter[whereEndedAtIsNull]=true&sort=lastName`,
        method: 'GET',
      }),
      providesTags: ['users'],
    }),
    getEmployee: build.query<ResponseWithPagination<EmployeeModel>, string | undefined>({
      query: (id) => ({
        url: `users/${id}`,
        method: 'GET',
      }),
      providesTags: ['users'],
    }),
    updateEmployee: build.mutation({
      query: (data) => ({
        url: `users/${data.id}`,
        method: 'PUT',
        data: {
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          employeeNumber: data.employeeNumber,
          startedAt: moment(data.startedAt).startOf('day').format(),
          endedAt: data.endedAt ? moment(data.endedAt).startOf('day').format() : undefined,
          remainingWorkDays: data.remainingWorkDays,
          extraVacationDays: data.extraVacationDays,
        },
      }),
      invalidatesTags: ['users'],
    }),
    updateSubmission: build.mutation({
      query: (data) => ({
        url: `submissions/${data.id}`,
        method: 'PUT',
        data: {
          description: data.description,
          vacationDays: data.vacationDays,
          status: data.status,
          submissionItems: data.submissionItems,
        },
      }),
      invalidatesTags: ['submissions', 'statistics'],
    }),
    getSalariesForEmployee: build.query<ResponseWithPagination<SalaryModel[]>, IPaginationRequest | undefined>({
      query: ({ id, page, size }: IPaginationRequest) => ({
        url: `salaries?filter[user.id]=${id}&page=${page}&size=${size}`,
        method: 'GET',
      }),
      providesTags: ['salaries'],
    }),
    getSubmissionsForEmployee: build.query<ResponseWithPagination<SubmissionModel[]>, IPaginationRequest | undefined>({
      query: ({ id, page, size }: IPaginationRequest) => ({
        url: `submissions?filter[user.id]=${id}&page=${page}&size=${size}`,
        method: 'GET',
      }),
      providesTags: ['submissions'],
    }),
    getCategories: build.query<ResponseWithPagination<CategoryModel[]>, IPaginationRequest>({
      query: ({ page, size }) => ({
        url: `categories?page=${page}&size=${size}`,
        method: 'GET',
      }),
      providesTags: ['categories'],
    }),
    getCategory: build.query<CategoryModel, string | undefined>({
      query: (id) => ({
        url: `categories/${id}`,
        method: 'GET',
      }),
      providesTags: ['categories'],
      transformResponse: (res) => new CategoryModel(res.data),
    }),
    updateCategory: build.mutation({
      query: (data) => ({
        url: `categories/${data.id}`,
        method: 'PUT',
        data: new CategoryModel(data).serialize(),
      }),
      invalidatesTags: ['categories'],
    }),
    createCategory: build.mutation({
      query: (data) => ({
        url: 'categories',
        method: 'POST',
        data: new CategoryModel(data).serialize(),
      }),
      invalidatesTags: ['categories'],
    }),
    createSubmissionForUser: build.mutation({
      query: ({ id, data }) => ({
        url: `users/${id}/submissions`,
        method: 'POST',
        data: {
          description: data.description,
          vacationDays: data.vacationDays,
          submissionItems: data.submissionItems,
        },
      }),
      invalidatesTags: ['submissions', 'statistics'],
    }),
    getSubmission: build.query<ResponseWithPagination<SubmissionModel>, string | undefined>({
      query: (id) => ({
        url: `submissions/${id}`,
        method: 'GET',
      }),
      providesTags: ['submissions'],
    }),
    getSubmissionItemsForSubmission: build.query<ResponseWithPagination<SubmissionItemModel[]>, IPaginationRequest | undefined>({
      query: ({ id, page, size }: IPaginationRequest) => ({
        url: `submissionItems?filter[submission.id]=${id}&page=${page}&size=${size}`,
        method: 'GET',
      }),
      providesTags: ['submissionItems'],
    }),
    createSubmissionItemForSubmission: build.mutation({
      query: (data) => ({
        url: `submissions/${data.id}/submissionItems`,
        method: 'POST',
        data: data.data,
      }),
      invalidatesTags: ['submissionItems'],
    }),
    updateSubmissionItem: build.mutation({
      query: (data) => ({
        url: `submissionItems/${data.id}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: ['submissionItems'],
    }),
    deleteSubmissionItem: build.mutation({
      query: (id) => ({
        url: `submissionItems/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['submissionItems'],
    }),
    createSalaryForUser: build.mutation({
      query: (data) => ({
        url: `users/${data.id}/salaries`,
        method: 'POST',
        data: data.data,
      }),
      invalidatesTags: ['salaries'],
    }),
    deleteSalary: build.mutation({
      query: (id) => ({
        url: `salaries/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['salaries'],
    }),
    getTotalSpentPerCategory: build.query<{data: { category: CategoryModel, totalAmount: number }[]}, number>({
      query: () => ({
        url: 'statistics/total-spent-per-category',
        method: 'GET',
      }),
      providesTags: ['statistics'],
    }),
    createFileForSubmission: build.mutation({
      query: (data) => ({
        url: `submissions/${data.id}/files`,
        method: 'POST',
        data: data.data,
      }),
      invalidatesTags: ['submissions'],
    }),
    deleteFile: build.mutation({
      query: (id) => ({
        url: `files/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['submissions'],
    }),
  }),
});

export const {
  useLoginMutation,
  useLogoutMutation,
  useGetAuthUserQuery,
  useCreateEmployeeMutation,
  useGetEmployeesQuery,
  useGetEmployeeQuery,
  useUpdateEmployeeMutation,
  useGetSalariesForEmployeeQuery,
  useGetSubmissionsForEmployeeQuery,
  useGetCategoriesQuery,
  useGetCategoryQuery,
  useUpdateCategoryMutation,
  useCreateCategoryMutation,
  useCreateSubmissionForUserMutation,
  useUpdateSubmissionMutation,
  useGetSubmissionQuery,
  useGetSubmissionItemsForSubmissionQuery,
  useUpdateSubmissionItemMutation,
  useDeleteSubmissionItemMutation,
  useCreateSubmissionItemForSubmissionMutation,
  useCreateSalaryForUserMutation,
  useDeleteSalaryMutation,
  useGetTotalSpentPerCategoryQuery,
  useCreateFileForSubmissionMutation,
  useDeleteFileMutation,
} = service;
