export default class EmployeeModel {
  public id = '';
  public firstName = '';
  public lastName = '';
  public email = '';
  public employeeNumber = '';
  public salary = 0;
  public budget = 0;
  public startedAt = '';
  public endedAt = '';
  public salaries? = [];
  public remainingWorkDays = 0;
  public extraVacationDays = 0;
}
