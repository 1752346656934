import moment from 'moment';
import { DataGrid } from 'relyus-components';
import { useCreateSalaryForUserMutation, useDeleteSalaryMutation, useGetSalariesForEmployeeQuery } from 'src/services/service';
import { useCallback, useEffect, useState } from 'react';
import { filters } from 'src/utils/array';
import { useParams } from 'react-router';
import SalaryModel from 'src/models/salary';
import { Button } from 'primereact/button';
import Price from 'src/components/Price';
import { DataTableRowEditParams } from 'primereact/datatable';
import CurrencyEditor from 'src/components/CurrencyEditor';
import DateEditor from 'src/components/DateEditor';
import { toast } from 'react-toastify';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';

function Date(rowData: SalaryModel): JSX.Element {
  const { activeAt } = rowData;
  return <span>{moment(activeAt).format('DD/MM/YYYY')}</span>;
}

interface SalaryWithIndexModel extends SalaryModel {
  index?: number;
}

function Salaries(): JSX.Element {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(15);
  const { id } = useParams();
  const [editingRows, setEditingRows] = useState({});

  const { data: salaries, isLoading } = useGetSalariesForEmployeeQuery({ id, page, size });
  const [create, { isLoading: isCreateLoading, isSuccess }] = useCreateSalaryForUserMutation();
  const [remove, { isLoading: isDeleteLoading }] = useDeleteSalaryMutation();
  const [_salaries, setSalaries] = useState<SalaryWithIndexModel[]>(salaries?.data || []);

  useEffect(() => {
    setSalaries(salaries?.data || []);
  }, [salaries]);

  useEffect(() => {
    if (isSuccess) {
      toast.success('Loon werd succesvol toegevoegd');
    }
  }, [isSuccess]);

  const Delete = useCallback((rowData: SalaryModel) => (
    <>
      <Button
        className="p-button-text p-button-danger"
        icon="pi pi-trash"
        onClick={(e) => {
          confirmPopup({
            target: e.currentTarget,
            message: 'Bent u zeker dat u deze lijn wilt verwijderen?',
            icon: 'pi pi-exclamation-triangle',
            accept: () => remove(rowData.id),
          });
        }}
      />
      <ConfirmPopup />
    </>
  ), [salaries]);

  const changePage = useCallback(({ first, rows }: { first: number, rows: number }) => {
    if (rows !== size) {
      setSize(rows);
      setPage(1);
    } else {
      setPage((first + size) / size);
    }
  }, [page, size]);

  const renderHeader = (): JSX.Element => (
    <div className="flex justify-content-between align-items-center">
      <h2 className="m-0 w-full">Lonen</h2>
      <Button
        type="button"
        icon="pi pi-plus"
        label="Voeg loon toe"
        className="mr-2 p-button-sm w-12rem"
        onClick={() => {
          const index = _salaries.length;
          setSalaries([...salaries?.data || [], {
            index,
            id: 0,
            amount: 0,
            ipReward: 0,
            activeAt: '',
          }]);
          setTimeout(() => {
            setEditingRows({ ...{ [index]: true } });
          }, 0);
        }}
      />
    </div>
  );

  const onRowEditChange = (e: DataTableRowEditParams): void => {
    setSalaries(salaries?.data || []);
    setEditingRows(e.data);
  };

  const onRowEditCancel = (): void => {
    setSalaries(salaries?.data || []);
  };

  return (
    <div className="h-full flex-grow-1 surface-border border-1 border-bottom-none border-round overflow-hidden">
      <DataGrid
        className="submissions"
        emptyMessage="Geen lonen gevonden"
        scrollHeight="flex"
        scrollDirection="vertical"
        scrollable
        lazy
        paginator
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        rowsPerPageOptions={[salaries?.meta.pagination.size, 10, 15, 25, 50].filter((v) => v).filter(filters.unique).sort() as number[]}
        currentPageReportTemplate="Toont {first} tot {last} van {totalRecords} lonen"
        value={_salaries.map((salary, index) => ({ index, ...salary }))}
        totalRecords={salaries?.meta.pagination.total || 0}
        size="normal"
        header={renderHeader}
        onRowEditChange={onRowEditChange}
        onRowEditCancel={onRowEditCancel}
        editingRows={editingRows}
        editMode="row"
        dataKey="index"
        columns={[
          {
            columnKey: 'amount', field: 'amount', header: 'Loon', body: Price('amount'), editor: CurrencyEditor, style: { minWidth: '30%' },
          },
          {
            columnKey: 'ipReward', field: 'ipReward', header: 'Auteursrechten', body: Price('ipReward'), editor: CurrencyEditor, style: { minWidth: '30%' },
          },
          {
            columnKey: 'activeAt', field: 'activeAt', header: 'Actief sinds', body: Date, editor: DateEditor, style: { minWidth: '25%' },
          },
          {
            columnKey: 'actions', rowEditor: true, style: { width: '10%' },
          },
          { columnKey: 'delete', body: Delete, style: { width: '5%' } },
        ]}
        rows={salaries?.meta.pagination.size || 0}
        loading={isLoading || isCreateLoading || isDeleteLoading}
        onPage={changePage}
        first={(page * size) - size}
        onRowEditComplete={(e) => create({ id, data: e.newData })}
      />
    </div>
  );
}

export default Salaries;
