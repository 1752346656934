import { DataGrid } from 'relyus-components';
import {
  useCallback, useEffect, useState,
} from 'react';
import { filters } from 'src/utils/array';
import { useNavigate, useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { useGetSubmissionsForEmployeeQuery, useUpdateSubmissionMutation } from 'src/services/service';
import { Button } from 'primereact/button';
import { Badge } from 'primereact/badge';
import { toast } from 'react-toastify';
import SubmissionModel from 'src/models/submission';
import { DataTableRowClickEventParams } from 'primereact';

function Action({ updateStatus, rowData }: { rowData: SubmissionModel, updateStatus: (status: string) => void }): JSX.Element {
  const { status } = rowData;

  if (status !== 'draft') {
    return (
      <Button icon="pi pi-undo" className="p-button-rounded p-button-text p-button-sm" onClick={() => updateStatus('draft')} />
    );
  }

  return (
    <>
      <Button icon="pi pi-check" className="p-button-rounded p-button-text p-button-sm mr-2" onClick={() => updateStatus('approved')} />
      <Button icon="pi pi-times" className="p-button-rounded p-button-text p-button-sm" onClick={() => updateStatus('rejected')} />
    </>
  );
}

function Submissions(): JSX.Element {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(15);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const { data: submissions, isLoading } = useGetSubmissionsForEmployeeQuery({ id, page, size });
  const [update, { isSuccess, isError }] = useUpdateSubmissionMutation();

  useEffect(() => {
    if (isSuccess) {
      toast.success('Status van aanvraag werd gewijzigd');
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      toast.error('Oops, er ging iets mis');
    }
  }, [isError]);

  const changePage = useCallback(({ first, rows }: { first: number, rows: number }) => {
    if (rows !== size) {
      setSize(rows);
      setPage(1);
    } else {
      setPage((first + size) / size);
    }
  }, [page, size]);

  const Status = useCallback((rowData: SubmissionModel): JSX.Element => {
    const { status } = rowData;
    switch (status) {
      case 'approved':
        return <Badge severity="success" value="Goedgekeurd" />;
      case 'rejected':
        return <Badge severity="danger" value="Afgewezen" />;
      default:
        return <Badge severity="info" value="Concept" />;
    }
  }, []);

  const Actions = useCallback((rowData: SubmissionModel) => (
    <Action
      rowData={rowData}
      updateStatus={(status: string) => update({ ...rowData, status })}
    />
  ), []);

  const navigateToAanvraag = useCallback((rowData: DataTableRowClickEventParams) => {
    const { data } = rowData;
    dispatch({ type: 'submissions/set', payload: data });
    navigate(`/medewerkers/${id}/aanvragen/${data.id}/gegevens`);
  }, []);

  return (
    <div className="h-full flex-grow-1 surface-border border-1 border-bottom-none border-round overflow-hidden">
      <DataGrid
        emptyMessage="Geen aanvragen gevonden"
        rowHover
        scrollHeight="flex"
        scrollDirection="vertical"
        scrollable
        lazy
        paginator
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        rowsPerPageOptions={[submissions?.meta.pagination.size, 10, 15, 25, 50].filter((v) => v).filter(filters.unique).sort() as number[]}
        currentPageReportTemplate="Toont {first} tot {last} van {totalRecords} aanvragen"
        value={submissions?.data || []}
        totalRecords={submissions?.meta.pagination.total || 0}
        size="normal"
        columns={[
          {
            columnKey: 'description', field: 'description', header: 'Omschrijving', style: { minWidth: '45%' },
          },
          {
            columnKey: 'status', field: 'status', header: 'Status', style: { minWidth: '40%' }, body: Status,
          },
          {
            columnKey: 'actions', header: 'Acties', style: { minWidth: '15%' }, body: Actions,
          },
        ]}
        rows={submissions?.meta.pagination.size || 0}
        loading={isLoading}
        onPage={changePage}
        first={(page * size) - size}
        onRowClick={navigateToAanvraag}
      />
    </div>
  );
}

export default Submissions;
