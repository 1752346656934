import {
  useEffect, ReactElement,
} from 'react';
import { useLocation, useNavigate } from 'react-router';
import PropTypes from 'prop-types';
import { useGetAuthUserQuery } from 'src/services/service';

function AuthProvider({ children }: { children: ReactElement }): JSX.Element | null {
  const location = useLocation();
  const navigate = useNavigate();
  const { isError } = useGetAuthUserQuery();

  useEffect(() => {
    if (isError) {
      if (!location.pathname.startsWith('/login')) {
        navigate(`/login?redirectUrl=${location.pathname}`);
      } else {
        navigate('/login');
      }
    }
  }, [isError]);

  return children;
}

AuthProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default AuthProvider;
