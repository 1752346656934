import { ProgressSpinner } from 'primereact/progressspinner';
import { ControllerRenderProps, FieldValues } from 'react-hook-form';
import { useParams } from 'react-router';
import { ControlledInput, Form, Validation } from 'relyus-components';
import { useGetSubmissionQuery } from 'src/services/service';
import { useOutletContext } from 'react-router-dom';
import { ISubmissionOutletContext } from 'src/views/Submission/Detail';
import { InputNumber } from 'primereact/inputnumber';

function Info(): JSX.Element {
  const { submissionId } = useParams();
  const { data: { data: submission } = {}, isLoading } = useGetSubmissionQuery(submissionId);
  const outlet: ISubmissionOutletContext = useOutletContext();

  if (isLoading) {
    return (
      <div className="h-full flex-grow-1">
        <div className="font-medium text-3xl text-900 mb-3 h-full flex align-items-center justify-content-center">
          <ProgressSpinner style={{ maxHeight: 50 }} />
        </div>
      </div>
    );
  }

  return (
    <Form defaultValues={submission as FieldValues}>
      {({ control, formState: { errors }, watch }) => {
        watch(outlet.updateSubmissionData);
        return (
          <>
            <ControlledInput
              className="input-group"
              control={control}
              rules={{
                ...Validation.required('Dit veld is verplicht.'),
                ...Validation.length({ min: 3, max: 255 }, 'Gelieve minimaal 3 en maximaal 255 karakters in te vullen'),
              }}
              name="description"
              label="Omschrijving"
              error={errors.description}
              disabled={outlet.approved}
            />
            <ControlledInput
              className="input-group"
              control={control}
              name="vacationDays"
              label="Vakantiedagen"
              render={({ field }: { field: ControllerRenderProps }) => (
                <InputNumber
                  {...field}
                  disabled={outlet.approved}
                  showButtons
                  min={0}
                  onChange={(e) => {
                    if (field.onChange) {
                      field.onChange(e.value);
                    }
                  }}
                />
              )}
            />
          </>
        );
      }}
    </Form>
  );
}

export default Info;
