import moment from 'moment';
import { DataGrid } from 'relyus-components';
import EmployeeModel from 'src/models/employee';
import { useGetEmployeesQuery } from 'src/services/service';
import {
  useCallback, useState,
} from 'react';
import { filters } from 'src/utils/array';
import { Outlet, useNavigate, useParams } from 'react-router';
import { Button } from 'primereact/button';
import CreateEmployee from 'src/views/Employees/Create';
import { InputText } from 'primereact/inputtext';
import { useDebounce } from 'use-debounce';

function Date(rowData: EmployeeModel): JSX.Element {
  const { startedAt } = rowData;
  return <span>{moment(startedAt).format('DD/MM/YYYY')}</span>;
}

function Budget(rowData: EmployeeModel): JSX.Element {
  const { budget } = rowData;
  return <span>{`€ ${budget.toFixed(2)}`}</span>;
}

function Employees(): JSX.Element {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(15);
  const [search, setSearch] = useState('');
  const [searchValue] = useDebounce(search, 250);
  const navigate = useNavigate();
  const { id } = useParams();
  const [showCreate, setShowCreate] = useState(false);

  const { data: employees, isLoading } = useGetEmployeesQuery({ page, size, search: searchValue });

  const changePage = useCallback(({ first, rows }: { first: number, rows: number }) => {
    if (rows !== size) {
      setSize(rows);
      setPage(1);
    } else {
      setPage((first + size) / size);
    }
  }, [page, size]);

  const renderHeader = (): JSX.Element => (
    <div className="flex justify-content-between align-items-center">
      <h2 className="m-0">Medewerkers</h2>
      <InputText value={search} onChange={(e) => setSearch(e.target.value)} placeholder="Zoeken" />
      <Button label="Nieuwe medewerker" className="p-button-sm p-button-outlined mr-2" icon="pi pi-user-plus" onClick={() => setShowCreate(true)} />
    </div>
  );

  const navigateToUser = useCallback(({ data: { id: userId } }: { data: { id: number }}) => {
    navigate(`/medewerkers/${userId}/gegevens`);
  }, []);

  if (id) {
    return <Outlet />;
  }

  return (
    <div className="p-4 h-full">
      <div className="surface-card shadow-2 border-round overflow-hidden h-full">
        <DataGrid
          emptyMessage="Geen medewerkers gevonden"
          rowHover
          scrollHeight="flex"
          scrollDirection="vertical"
          scrollable
          header={renderHeader}
          lazy
          paginator
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          rowsPerPageOptions={[employees?.meta.pagination.size, 10, 15, 25, 50].filter((v) => v).filter(filters.unique).sort() as number[]}
          currentPageReportTemplate="Toont {first} tot {last} van {totalRecords} medewerkers"
          rows={employees?.meta.pagination.size || 0}
          loading={isLoading}
          onPage={changePage}
          first={(page * size) - size}
          totalRecords={employees?.meta.pagination.total || 0}
          value={employees?.data || []}
          size="normal"
          columns={[
            {
              columnKey: 'firstName', field: 'firstName', header: 'Voornaam', style: { minWidth: '15%' },
            },
            {
              columnKey: 'lastName', field: 'lastName', header: 'Achternaam', style: { minWidth: '15%' },
            },
            {
              columnKey: 'email', field: 'email', header: 'E-mailadres', style: { minWidth: '29%' },
            },
            {
              columnKey: 'startedAt', header: 'In dienst sinds', body: Date, style: { minWidth: '15%' },
            },
            {
              columnKey: 'budget', field: 'budget', header: 'Budget', style: { minWidth: '10%' }, body: Budget,
            },
          ]}
          onRowClick={navigateToUser}
        />
      </div>
      <CreateEmployee visible={showCreate} setVisible={setShowCreate} />
    </div>
  );
}

export default Employees;
