import { ProgressSpinner } from 'primereact/progressspinner';
import {
  Outlet, useLocation, useNavigate, useParams,
} from 'react-router';
import { useGetEmployeeQuery, useUpdateEmployeeMutation } from 'src/services/service';
import { TabMenu, TabMenuTabChangeParams } from 'primereact/tabmenu';
import { Button } from 'primereact/button';
import NotFound from 'src/views/NotFound';
import {
  useCallback, useEffect, useState,
} from 'react';
import { FieldValues } from 'react-hook-form';
import { toast } from 'react-toastify';
import { MenuItem } from 'primereact/menuitem';
import CreateSubmission from 'src/views/Employees/views/Submissions/Create';

interface TabMenuTabChangeParamsWithSlug extends TabMenuTabChangeParams {
  slug: string,
}
export interface IEmployeeOutletContext {
  updateUserData: (values: FieldValues) => void;
}

function Employee(): JSX.Element {
  const { id, submissionId } = useParams();
  const { data: { data: user } = {}, isLoading } = useGetEmployeeQuery(id);
  const [update, { isSuccess }] = useUpdateEmployeeMutation();

  const [userData, updateUserData] = useState({});

  const [showCreate, setShowCreate] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const saveUser = useCallback(() => {
    update(userData);
  }, [userData]);

  const items = [
    { label: 'Gegevens', slug: 'gegevens' },
    { label: 'Overzicht lonen', slug: 'lonen' },
    { label: 'Aanvragen', slug: 'aanvragen' },
  ].filter((v) => v) as MenuItem[];

  useEffect(() => {
    if (isSuccess) {
      toast.success('Medewerker werd succesvol gewijzigd');
    }
  }, [isSuccess]);

  useEffect(() => {
    if (!isLoading) {
      if (user) {
        updateUserData(user);
      }
    }
  }, [isLoading]);

  if (isLoading) {
    return (
      <div className="p-4 h-full">
        <div className="surface-card shadow-2 border-round overflow-hidden h-full">
          <div className="font-medium text-3xl text-900 mb-3 h-full flex align-items-center justify-content-center">
            <ProgressSpinner style={{ maxHeight: 50 }} />
          </div>
        </div>
      </div>
    );
  }

  if (!user) {
    return <NotFound />;
  }

  if (submissionId) {
    return <Outlet />;
  }

  const selectedItemIndex = items.findIndex((item) => (item as TabMenuTabChangeParamsWithSlug).slug === location.pathname.split('/').pop());

  return (
    <div className="p-4 h-full">
      <div className="surface-card shadow-2 border-round overflow-hidden h-full flex flex-column">
        <div className="surface-section px-4 py-5">
          <div className="flex flex-column md:align-items-center md:justify-content-between md:flex-row">
            <div className="font-medium text-3xl text-900">
              {`${user.firstName} ${user.lastName} `}
              <small>
                (Budget: €
                {user.budget}
                )
              </small>
            </div>
            <div className="mt-3 md:mt-0">
              <Button
                icon="pi pi-plus"
                label="Nieuwe aanvraag"
                className="p-button-success p-button-sm mr-2"
                onClick={() => setShowCreate(true)}
              />
              <Button label="Sla op" icon="pi pi-check" className="p-button-sm" onClick={saveUser} />
            </div>
          </div>
        </div>
        <TabMenu
          model={items}
          activeIndex={selectedItemIndex}
          onTabChange={(e) => {
            navigate((e.value as TabMenuTabChangeParamsWithSlug).slug);
          }}
        />
        <div className="p-4 overflow-y-auto flex-grow-1">
          <Outlet context={{ updateUserData }} />
        </div>
      </div>
      <CreateSubmission visible={showCreate} setVisible={setShowCreate} />
    </div>
  );
}

export default Employee;
