import { ReactElement } from 'react';
import LoginForm from 'src/forms/Login';

function Login(): ReactElement {
  return (
    <>
      <div className="surface-section md:w-4 p-6 md:p-8" style={{ minHeight: 600, maxWidth: 600 }}>
        <div className="mb-5">
          <img src={process.env.REACT_APP_LOGO_FILENAME} alt="logo" height="50" />
        </div>
        <LoginForm />
      </div>
      <div
        className="hidden md:block w-6 bg-no-repeat bg-cover"
        style={{
          minHeight: 600,
          maxWidth: 600,
          background: `url(${process.env.REACT_APP_LOGIN_BACKGROUND})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
        }}
      />
    </>
  );
}

export default Login;
