// eslint-disable-next-line max-classes-per-file
import moment from 'moment';

interface ICategoryModel {
  id?: number;
  name: string;
  parentId?: number;
  taxAmount?: number;
  writeOffYears?: number;
  endedAt?: string;
}

export default class CategoryModel {
  public id = 0;
  public isParent = true;
  public name = '';
  public parentId = null;
  public taxAmount = 0;
  public writeOffYears = 0;
  public endedAt = '';
  public children = [];

  constructor(c?: CategoryModel) {
    if (c) {
      Object.assign(this, c);
      this.isParent = !c.parentId;
    }
  }

  public serialize(): ICategoryModel {
    return {
      name: this.name,
      parentId: this.parentId || undefined,
      taxAmount: this.taxAmount || undefined,
      writeOffYears: this.writeOffYears || undefined,
      endedAt: this.endedAt ? moment(this.endedAt).startOf('day').format() : undefined,
    };
  }
}

export class CategoryTreeModel {
  key: number;
  label: string;
  children: unknown[];

  constructor(c: CategoryModel) {
    this.key = c.id;
    this.label = c.name;
    this.children = c.children?.map((child) => new CategoryTreeModel(child));
  }
}
