import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { useCreateSubmissionForUserMutation, useGetEmployeeQuery } from 'src/services/service';
import SideForm from 'src/components/SideForm';
import { InputNumber } from 'primereact/inputnumber';
import { Divider } from 'primereact/divider';
import { ControlledInput, Validation } from 'relyus-components';
import { ControllerRenderProps, FieldValues } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { SubmissionsState } from 'src/services/submissions';
import SubmissionItemsTable from 'src/views/Submission/components/SubmissionItems';
import SubmissionItemModel from 'src/models/submissionItem';
import SubmissionModel from 'src/models/submission';

interface ICreateSubmissionProps {
  visible: boolean;
  setVisible: (bool: boolean) => void;
}

function CreateSubmission(props: ICreateSubmissionProps): JSX.Element {
  const { visible, setVisible } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { data: { data: user } = {} } = useGetEmployeeQuery(id);
  const [create, { data: newSubmission, isSuccess: isCreated }] = useCreateSubmissionForUserMutation();

  const submissionsStore = useSelector(({ submissions }: { submissions: SubmissionsState }) => submissions);
  const [lineItems, setLineItems] = useState<SubmissionItemModel[]>(submissionsStore.data.submissionItems || []);

  useEffect(() => {
    dispatch({ type: 'submissions/set', payload: new SubmissionItemModel() });
  }, []);

  useEffect(() => {
    if (isCreated) {
      toast.success('Aanvraag werd succesvol opgeslagen');
      dispatch({ type: 'submissions/set', payload: new SubmissionModel() });
      setVisible(false);
      navigate(`/medewerkers/${id}/aanvragen/${newSubmission.data?.id}/gegevens`);
    }
  }, [isCreated]);

  return (
    <SideForm
      title={`Nieuwe aanvraag - ${user?.firstName} ${user?.lastName}`}
      onSubmit={(data: FieldValues) => create({ id, data: { ...data, submissionItems: lineItems } })}
      defaultValues={submissionsStore.data}
      visible={visible}
      setVisible={setVisible}
    >
      {({ control, formState: { errors } }) => (
        <>
          <ControlledInput
            className="input-group"
            control={control}
            rules={{
              ...Validation.required('Dit veld is verplicht.'),
              ...Validation.length({ min: 3, max: 255 }, 'Gelieve minimaal 3 en maximaal 255 karakters in te vullen'),
            }}
            name="description"
            label="Omschrijving"
            error={errors.description}
          />
          <ControlledInput
            className="input-group"
            control={control}
            name="vacationDays"
            label="Vakantiedagen"
            render={({ field }: { field: ControllerRenderProps }) => (
              <InputNumber
                {...field}
                showButtons
                min={0}
                onChange={(e) => {
                  if (field.onChange) {
                    field.onChange(e.value);
                  }
                }}
              />
            )}
          />
          <Divider />
          <SubmissionItemsTable items={lineItems} onRowChanged={setLineItems} />
        </>
      )}
    </SideForm>
  );
}

export default CreateSubmission;
