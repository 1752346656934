import { createAction, createReducer } from '@reduxjs/toolkit';
import EmployeeModel from 'src/models/employee';

const set = createAction<EmployeeModel>('employees/set');

export interface IEmployeesState {
  data: EmployeeModel
}

const initialState: IEmployeesState = { data: new EmployeeModel() };

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(set, (state, action) => {
      const newState = state;
      newState.data = action.payload;
    });
});

export const employees = {
  reducerPath: 'employees',
  reducer,
};
