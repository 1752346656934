import { FieldValues } from 'react-hook-form';
import SubmissionItemModel from 'src/models/submissionItem';

function Price(name: string): (rowData: SubmissionItemModel) => JSX.Element {
  return (rowData: SubmissionItemModel): JSX.Element => (
    <span>
      €
      {' '}
      {(rowData as FieldValues)[name].toFixed(2)}
    </span>
  );
}

export default Price;
