import Cookies from 'js-cookie';

interface ICookies {
    [key: string]: string,
}

const cookies: ICookies = {};

interface ICookieService {
    set: (key: string, value: string) => void,
    get: (key: string) => string,
    remove: (key: string) => void,
}

export default function CookieService(): ICookieService {
  function set(key: string, value: string): void {
    cookies[key] = value;
    Cookies.set(key, value);
  }

  function get(key: string): string {
    return cookies[key] || Cookies.get(key) || '';
  }

  function remove(key: string): void {
    delete cookies[key];
    Cookies.remove(key);
  }

  return {
    set,
    get,
    remove,
  };
}
