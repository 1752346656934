import { ProgressSpinner } from 'primereact/progressspinner';
import { useNavigate, useParams } from 'react-router';
import {
  ControlledInput, DataGrid, Form, Validation,
} from 'relyus-components';
import {
  useGetCategoriesQuery, useGetCategoryQuery, useUpdateCategoryMutation,
} from 'src/services/service';
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { Calendar, CalendarProps } from 'primereact/calendar';
import NotFound from 'src/views/NotFound';
import { useCallback, useEffect, useState } from 'react';
import { ControllerRenderProps, FieldValues } from 'react-hook-form';
import { toast } from 'react-toastify';
import CategoryModel from 'src/models/category';
import { TabView, TabPanel } from 'primereact/tabview';
import moment from 'moment';

function WriteOffYears(rowData: CategoryModel): JSX.Element {
  const { writeOffYears } = rowData;
  return <span>{`${writeOffYears || 0} jaar`}</span>;
}

function Category(): JSX.Element {
  const [activeIndex, setActiveIndex] = useState(0);
  const navigate = useNavigate();
  const { id } = useParams();
  const { data: category, isLoading = true } = useGetCategoryQuery(id);
  const [update, { isSuccess: isUpdated }] = useUpdateCategoryMutation();

  const [formData, updateFormData] = useState({});

  const { data: { data: categories } = {} } = useGetCategoriesQuery({ page: 1, size: 15 });

  const save = useCallback(() => {
    update(formData);
  }, [formData]);

  useEffect(() => {
    if (category) {
      updateFormData(category);
    }
  }, [category]);

  useEffect(() => {
    setActiveIndex(0);
  }, [id]);

  useEffect(() => {
    if (isUpdated) {
      toast.success('Categorie werd succesvol gewijzigd');
      navigate(`/instellingen/categorieen/${id}`);
    }
  }, [isUpdated]);

  useEffect(() => {
    if (!isLoading) {
      if (category) {
        updateFormData(category);
      }
    }
  }, [isLoading]);

  const navigateToCategory = useCallback(({ data: { id: categoryId } }: { data: { id: number }}) => {
    navigate(`/instellingen/categorieen/${categoryId}`);
  }, []);

  if (isLoading) {
    return (
      <div className="p-4 h-full">
        <div className="surface-card shadow-2 border-round overflow-hidden h-full">
          <div className="font-medium text-3xl text-900 mb-3 h-full flex align-items-center justify-content-center">
            <ProgressSpinner style={{ maxHeight: 50 }} />
          </div>
        </div>
      </div>
    );
  }

  if (!category) {
    return <NotFound />;
  }

  return (
    <div className="h-full">
      <div className="h-full flex flex-column">
        <div className="surface-section mb-4">
          <div className="flex flex-column md:align-items-center md:justify-content-between md:flex-row">
            <div className="font-medium text-2xl text-900">
              {category.name}
            </div>
          </div>
        </div>
        <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
          <TabPanel header="Gegevens">
            <div className="flex-grow-1">
              <Form key={category?.id} defaultValues={category as FieldValues} onSubmit={save}>
                {({ control, formState: { errors }, watch }) => {
                  watch(updateFormData);
                  return (
                    <>
                      <ControlledInput
                        className="input-group"
                        control={control}
                        rules={{
                          ...Validation.required('Dit veld is verplicht.'),
                          ...Validation.length({ min: 3, max: 255 }, 'Gelieve minimaal 3 en maximaal 255 karakters in te vullen'),
                        }}
                        name="name"
                        label="Naam"
                        error={errors.name}
                      />
                      { (formData as CategoryModel).isParent && (
                      <>
                        <ControlledInput
                          className="input-group"
                          control={control}
                          name="taxAmount"
                          label="Voordeel alle aard"
                          render={({ field }: { field: ControllerRenderProps }) => (
                            <InputNumber
                              {...field}
                              mode="currency"
                              currency="EUR"
                              showButtons
                              min={0}
                              step={0.01}
                              onChange={(e) => {
                                if (field.onChange) {
                                  field.onChange(e.value);
                                }
                              }}
                            />
                          )}
                        />
                        <ControlledInput
                          className="input-group"
                          control={control}
                          rules={{
                            ...Validation.number({ min: 1 }, 'Dit veld is verplicht.'),
                          }}
                          error={errors.writeOffYears}
                          name="writeOffYears"
                          label="Afschrijving"
                          render={({ field }: { field: ControllerRenderProps }) => (
                            <InputNumber
                              {...field}
                              suffix=" jaar"
                              onChange={(e) => {
                                if (field.onChange) {
                                  field.onChange(e.value);
                                }
                              }}
                            />
                          )}
                        />
                      </>
                      )}
                      { !(formData as CategoryModel).isParent && (
                      <ControlledInput
                        className="input-group"
                        control={control}
                        rules={{
                          ...Validation.required('Dit veld is verplicht.'),
                        }}
                        error={errors.parentId}
                        name="parentId"
                        label="Hoofdcategorie"
                        render={({ field }: { field: ControllerRenderProps }) => (
                          <Dropdown
                            {...field}
                            placeholder="Selecteer een categorie"
                            optionLabel="name"
                            optionValue="id"
                            options={categories}
                            onChange={(e) => {
                              if (field.onChange) {
                                field.onChange(e);
                              }
                            }}
                          />
                        )}
                      />
                      )}
                      <ControlledInput
                        className="input-group"
                        control={control}
                        name="endedAt"
                        label="Einddatum"
                        render={({ field }: { field: CalendarProps }) => (
                          <Calendar
                            {...field}
                            value={moment(field.value as Date).toDate()}
                            showIcon
                            dateFormat="dd/mm/yy"
                            onChange={(e) => {
                              if (field.onChange) {
                                field.onChange(e);
                              }
                            }}
                          />
                        )}
                      />
                      <Button label="Sla op" type="submit" icon="pi pi-check" style={{ width: 'auto' }} />
                    </>
                  );
                }}
              </Form>
            </div>
          </TabPanel>
          { category.isParent && (
          <TabPanel header="Subcategorieën">
            <DataGrid
              emptyMessage="Geen subcategorieën gevonden"
              rowHover
              scrollHeight="flex"
              scrollDirection="vertical"
              scrollable
              value={category.children}
              size="normal"
              columns={[
                {
                  columnKey: 'name', field: 'name', header: 'Naam', style: { minWidth: '25%' },
                },
                {
                  columnKey: 'writeOffYears', field: 'writeOffYears', header: 'Afschrijving', style: { minWidth: '25%' }, body: WriteOffYears,
                },
              ]}
              onRowClick={navigateToCategory}
            />
          </TabPanel>
          )}
        </TabView>
      </div>
    </div>
  );
}

export default Category;
