import { AxiosResponse } from 'axios';
import { axiosBaseQuery } from '../utils/axios';

const request = axiosBaseQuery({
  baseUrl: process.env.REACT_APP_API_BASE_URL || '/api/v1',
  raw: true,
});

export interface IFile {
  id: number;
  submissionId: number;
  fileName: string;
  url: string;
}

const download = (response: AxiosResponse, fileName?: string): void => {
  const name = fileName || response.headers['content-disposition'].split('filename=')[1].replace(/"/, '').trim();
  const type = response.headers['content-type'];
  const blob = new Blob([response.data], { type });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = name;
  link.click();
};

const getFile = (data: IFile): Promise<void|AxiosResponse> => request({
  url: `files/${data.id}`,
  method: 'GET',
  headers: {
    Accept: 'application/pdf,text/plain',
  },
  data: {},
  responseType: 'blob',
}).then((response) => response.data)
  .then((response) => download(response as AxiosResponse, data.fileName));

export {
  getFile,
};
