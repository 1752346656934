import { ReactElement, useState, useEffect } from 'react';
import {
  RouteObject, useRoutes,
} from 'react-router-dom';

import AuthProvider from 'src/components/AuthProvider';
import getRoutes from 'src/routes';
import CookieService from 'src/services/cookie';

function App(): ReactElement {
  const [routes, setRoutes] = useState<RouteObject[]>([]);

  useEffect(() => {
    getRoutes().then((r) => setRoutes(r));
  }, [CookieService().get('token')]);

  return (
    <AuthProvider>
      {useRoutes(routes) || <div />}
    </AuthProvider>
  );
}

export default App;
