import { ReactElement } from 'react';
import { Outlet } from 'react-router-dom';

function Unauthenticated(): ReactElement {
  return (
    <div className="p-component p-d-flex p-flex-row">
      <div className="max-h-screen flex relative lg:static surface-ground">
        <div className="min-h-screen flex flex-column relative flex-auto">
          <div className="overflow-y-auto flex flex-column flex-auto">
            <div className="flex flex-row w-full justify-content-center md:align-items-center" style={{ height: '100%' }}>
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Unauthenticated;
