import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { toast } from 'react-toastify';
import CookieService from 'src/services/cookie';

export const axiosBaseQuery = ({ baseUrl, raw = false }: { baseUrl: string, raw?: boolean }) => async function request({
  url, method, data, headers = {}, responseType,
}: AxiosRequestConfig) {
  const instance = axios.create();

  instance.defaults.baseURL = baseUrl;
  instance.defaults.headers.common.Accept = 'application/json';

  if (CookieService().get('token')) {
    instance.defaults.headers.common.Authorization = `Bearer ${CookieService().get('token')}`;
  }

  if (!raw) {
    instance.interceptors.response.use((response) => response.data, (error) => {
      if (error.response.status !== 401) {
        toast.error(error.response.data.message, {
          position: 'top-right',
        });
      }
      throw error;
    });
  }

  try {
    const result = await instance({
      url, method, data, headers, responseType,
    });

    if (result.data.token) {
      CookieService().set('token', result.data.token);
    }

    return {
      data: result,
    };
  } catch (error) {
    return {
      error: {
        status: (error as AxiosError).response?.status,
        data: (error as AxiosError).response?.data,
      },
    };
  }
};

export default axios;
