import { Button } from 'primereact/button';
import { ColumnEditorOptions } from 'primereact/column';
import { DataTableRowEditCompleteParams, DataTableRowEditParams } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { TreeSelect } from 'primereact/treeselect';
import TreeNode from 'primereact/treenode';
import { useCallback, useEffect, useState } from 'react';
import { DataGrid, DataGridProps } from 'relyus-components';
import Price from 'src/components/Price';
import SubmissionItemModel from 'src/models/submissionItem';
import { useGetCategoriesQuery } from 'src/services/service';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import CurrencyEditor from 'src/components/CurrencyEditor';
import CategoryModel, { CategoryTreeModel } from 'src/models/category';

// INTERFACES
interface SubmissionItemsProps extends DataGridProps {
  items: SubmissionItemModel[],
  disabled?: boolean;
  onRowChanged?: (e: SubmissionItemModel[]) => void,
  onRowEditComplete?: (e: DataTableRowEditCompleteParams) => void,
  onDeleteRow?: (e: SubmissionItemModel) => void,
}

interface SubmissionItemWithIndex extends SubmissionItemModel {
  index?: number;
}

// COMPONENT
function SubmissionItemsTable(props: SubmissionItemsProps): JSX.Element {
  // VARIABLES
  const {
    items, onDeleteRow, onRowEditComplete, onRowChanged, ...rest
  } = props;
  const { data: { data: categories } = {} } = useGetCategoriesQuery({ page: 1, size: 999 });
  const [editingRows, setEditingRows] = useState({});
  const [lineItems, setLineItems] = useState<SubmissionItemWithIndex[]>(items);

  // EFFECTS
  useEffect(() => {
    if (onRowChanged) {
      onRowChanged(lineItems);
    }
  }, [lineItems]);

  useEffect(() => {
    setLineItems(items);
  }, [items]);

  // FUNCTIONS
  const deleteLineItem = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>, rowData: SubmissionItemWithIndex) => {
    const deleteFn = (): void => {
      if (onDeleteRow) {
        onDeleteRow(rowData);
      } else {
        setLineItems(lineItems.filter((item, index) => index !== rowData.index));
      }
    };

    if (onDeleteRow) {
      confirmPopup({
        target: e.currentTarget,
        message: 'Bent u zeker dat u deze lijn wilt verwijderen?',
        icon: 'pi pi-exclamation-triangle',
        accept: deleteFn,
      });
    } else {
      deleteFn();
    }
  }, [lineItems]);

  const addLineItem = useCallback(() => {
    setLineItems([...items, {
      index: items.length,
      productName: '',
      description: '',
      priceExclTax: 0,
      taxAmount: 0,
      categoryId: 0,
      pieces: 1,
    }]);
    setTimeout(() => {
      setEditingRows({ ...{ [items.length]: true } });
    }, 0);
  }, [items]);

  const _onRowEditComplete = (e: DataTableRowEditCompleteParams): void => {
    const newLineItems = [...lineItems];
    const { newData, index } = e;

    newLineItems[index] = newData;

    setLineItems(newLineItems);
  };

  const onRowEditChange = (e: DataTableRowEditParams): void => {
    setEditingRows(e.data);
  };

  const onRowEditCancel = (): void => {
    setLineItems(items || []);
  };

  // COMPONENTS
  const Category = useCallback((rowData: SubmissionItemModel): JSX.Element => {
    const { categoryId } = rowData;
    return (
      <span>
        {(categories
          ?.reduce((acc: CategoryModel[], category) => acc.concat([category], category.children), []) as CategoryModel[])
          ?.find((c) => c.id === categoryId)
          ?.name}
      </span>
    );
  }, [categories]);

  const Delete = useCallback((rowData: SubmissionItemModel) => (
    <>
      <Button
        className="p-button-text p-button-danger"
        icon="pi pi-trash"
        onClick={(e) => deleteLineItem(e, rowData)}
      />
      <ConfirmPopup />
    </>
  ), [lineItems]);

  const textEditor = (options: ColumnEditorOptions): JSX.Element => <InputText value={options.value} onChange={(e) => options.editorCallback?.(e.target.value)} />;

  const categoryEditor = (options: ColumnEditorOptions): JSX.Element => (
    <TreeSelect
      value={options.value}
      options={categories?.map((c) => new CategoryTreeModel(c)) as TreeNode[]}
      selectionMode="single"
      placeholder="Selecteer een categorie"
      onChange={(e) => options.editorCallback?.(e.value)}
    />
  );

  const renderHeader = (): JSX.Element => (
    <div className="flex justify-content-between align-items-center">
      <h2 className="m-0 w-full">Lijnen</h2>
      <Button
        type="button"
        icon="pi pi-plus"
        label="Voeg lijn toe"
        className="mr-2 p-button-sm w-12rem"
        onClick={addLineItem}
      />
    </div>
  );

  // RENDER
  return (
    <DataGrid
      {...rest}
      header={renderHeader}
      value={lineItems.map((lineItem, index) => ({ ...lineItem, index }))}
      className="mb-3"
      emptyMessage="Nog geen lijnen toegevoegd"
      editMode="row"
      onRowEditComplete={onRowEditComplete || _onRowEditComplete}
      editingRows={editingRows}
      onRowEditChange={onRowEditChange}
      onRowEditCancel={onRowEditCancel}
      dataKey="index"
      columns={[
        {
          columnKey: 'productName', field: 'productName', header: 'Naam', editor: textEditor, style: { width: '15%' },
        },
        {
          columnKey: 'description', field: 'description', header: 'Omschrijving', editor: textEditor, style: { width: '20%' },
        },
        {
          columnKey: 'priceExclTax', field: 'priceExclTax', header: 'Prijs excl.', body: Price('priceExclTax'), editor: CurrencyEditor, style: { width: '12.5%' },
        },
        {
          columnKey: 'taxAmount', field: 'taxAmount', header: 'B.T.W.', body: Price('taxAmount'), editor: CurrencyEditor, style: { width: '12.5%' },
        },
        {
          columnKey: 'categoryId', field: 'categoryId', header: 'Categorie', body: Category, editor: categoryEditor, style: { width: '25%' },
        },
        { columnKey: 'actions', rowEditor: true, style: { width: '10%' } },
        { columnKey: 'delete', body: Delete, style: { width: '5%' } },
      ]}
    />
  );
}

SubmissionItemsTable.defaultProps = {
  disabled: false,
  onRowEditComplete: undefined,
  onDeleteRow: undefined,
  onRowChanged: undefined,
};

export default SubmissionItemsTable;
