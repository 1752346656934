import {
  useCreateSubmissionItemForSubmissionMutation, useDeleteSubmissionItemMutation, useGetSubmissionItemsForSubmissionQuery, useUpdateSubmissionItemMutation,
} from 'src/services/service';
import { useCallback, useEffect, useState } from 'react';
import { useOutletContext, useParams } from 'react-router';
import SubmissionItemsTable from 'src/views/Submission/components/SubmissionItems';
import { filters } from 'src/utils/array';
import { toast } from 'react-toastify';
import { ISubmissionOutletContext } from './Detail';

function Items(): JSX.Element|null {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(15);
  const { id } = useParams();
  const outlet: ISubmissionOutletContext = useOutletContext();

  const { data: items, isLoading } = useGetSubmissionItemsForSubmissionQuery({ id, page: 1, size: 10 });
  const [updateLine, { isLoading: isLoadingUpdate, isSuccess: isUpdateSuccess }] = useUpdateSubmissionItemMutation();
  const [createLine, { isLoading: isLoadingCreate, isSuccess: isCreateSuccess }] = useCreateSubmissionItemForSubmissionMutation();
  const [remove, { isLoading: isLoadingDelete, isSuccess: isDeleteSuccess }] = useDeleteSubmissionItemMutation();

  useEffect(() => {
    if (isUpdateSuccess) {
      toast.success('Lijn werd succesvol gewijzigd');
    }
  }, [isUpdateSuccess]);

  useEffect(() => {
    if (isDeleteSuccess) {
      toast.success('Lijn werd succesvol verwijdered');
    }
  }, [isDeleteSuccess]);

  useEffect(() => {
    if (isCreateSuccess) {
      toast.success('Lijn werd succesvol toegevoegd');
    }
  }, [isCreateSuccess]);

  useEffect(() => {
    if (isCreateSuccess) {
      toast.success('Lijn werd succesvol toegevoegd');
    }
  }, [isCreateSuccess]);

  const changePage = useCallback(({ first, rows }: { first: number, rows: number }) => {
    if (rows !== size) {
      setSize(rows);
      setPage(1);
    } else {
      setPage((first + size) / size);
    }
  }, [page, size]);

  return (
    <div className="h-full flex-grow-1 surface-border border-1 border-bottom-none border-round overflow-hidden">
      <SubmissionItemsTable
        disabled={outlet?.approved}
        scrollHeight="flex"
        scrollDirection="vertical"
        scrollable
        lazy
        paginator
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        rowsPerPageOptions={[10, 15, 25, 50].filter((v) => v).filter(filters.unique).sort() as number[]}
        currentPageReportTemplate="Toont {first} tot {last} van {totalRecords} lijnen"
        totalRecords={items?.meta.pagination.total || 0}
        size="normal"
        items={items?.data || []}
        rows={items?.meta.pagination.size || 0}
        onPage={changePage}
        first={(page * size) - size}
        loading={isLoading || isLoadingCreate || isLoadingUpdate || isLoadingDelete}
        onRowEditComplete={(e) => {
          if (!e.newData.id) {
            createLine({ id, data: e.newData });
          } else {
            updateLine(e.newData);
          }
        }}
        onDeleteRow={(e) => remove(e.id)}
      />
    </div>
  );
}

export default Items;
