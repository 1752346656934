import { DataGrid } from 'relyus-components';
import { useGetCategoriesQuery } from 'src/services/service';
import { useCallback, useState } from 'react';
import { filters } from 'src/utils/array';
import { Outlet, useNavigate, useParams } from 'react-router';
import CategoryModel from 'src/models/category';

function TaxAmount(rowData: CategoryModel): JSX.Element {
  const { taxAmount } = rowData;
  return <span>{`€ ${taxAmount.toFixed(2)}`}</span>;
}

function WriteOffYears(rowData: CategoryModel): JSX.Element {
  const { writeOffYears } = rowData;
  return <span>{`${writeOffYears} jaar`}</span>;
}

function SubCategories(rowData: CategoryModel): JSX.Element {
  const { children } = rowData;
  return <span>{children.length}</span>;
}

function Categories(): JSX.Element {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(15);
  const navigate = useNavigate();
  const { id } = useParams();

  const { data: categories, isLoading } = useGetCategoriesQuery({ page, size });

  const changePage = useCallback(({ first, rows }: { first: number, rows: number }) => {
    if (rows !== size) {
      setSize(rows);
      setPage(1);
    } else {
      setPage((first + size) / size);
    }
  }, [page, size]);

  const navigateToCategory = useCallback(({ data: { id: categoryId } }: { data: { id: number }}) => {
    navigate(`/instellingen/categorieen/${categoryId}`);
  }, []);

  if (id) {
    return <Outlet />;
  }

  return (
    <div className="h-full flex-grow-1 surface-border border-1 border-bottom-none border-round overflow-hidden">
      <DataGrid
        emptyMessage="Geen categorieën gevonden"
        rowHover
        scrollHeight="flex"
        scrollDirection="vertical"
        scrollable
        lazy
        paginator
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        rowsPerPageOptions={[categories?.meta.pagination.size, 10, 15, 25, 50].filter((v) => v).filter(filters.unique).sort() as number[]}
        currentPageReportTemplate="Toont {first} tot {last} van {totalRecords} categorieën"
        value={categories?.data || []}
        totalRecords={categories?.meta.pagination.total || 0}
        size="normal"
        columns={[
          {
            columnKey: 'name', field: 'name', header: 'Naam', style: { minWidth: '25%' },
          },
          {
            columnKey: 'taxAmount', field: 'taxAmount', header: 'VAA', style: { minWidth: '25%' }, body: TaxAmount,
          },
          {
            columnKey: 'writeOffYears', field: 'writeOffYears', header: 'Afschrijving', style: { minWidth: '25%' }, body: WriteOffYears,
          },
          {
            columnKey: 'children', header: 'Aantal subcategorieën', style: { minWidth: '25%' }, body: SubCategories,
          },
        ]}
        onRowClick={navigateToCategory}
        rows={categories?.meta.pagination.size || 0}
        loading={isLoading}
        onPage={changePage}
        first={(page * size) - size}
      />
    </div>
  );
}

export default Categories;
