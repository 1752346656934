import { ProgressSpinner } from 'primereact/progressspinner';
import {
  Outlet, useLocation, useNavigate, useParams,
} from 'react-router';
import { useGetSubmissionQuery, useUpdateSubmissionMutation } from 'src/services/service';
import { TabMenu, TabMenuTabChangeParams } from 'primereact/tabmenu';
import { Button } from 'primereact/button';
import NotFound from 'src/views/NotFound';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { MenuItem } from 'primereact/menuitem';
import { FieldValues } from 'react-hook-form';

interface TabMenuTabChangeParamsWithSlug extends TabMenuTabChangeParams {
  slug: string,
}

export interface ISubmissionOutletContext {
  updateSubmissionData: (values: FieldValues) => void;
  approved: boolean;
}

function Submission(): JSX.Element {
  const { submissionId } = useParams();
  const { data: { data: submission } = {}, isLoading } = useGetSubmissionQuery(submissionId);
  const [update, { isSuccess }] = useUpdateSubmissionMutation();
  const [approved, setApproved] = useState(false);

  const [submissionData, updateSubmissionData] = useState({});

  const navigate = useNavigate();
  const location = useLocation();

  const saveAanvraag = useCallback(() => {
    update(submissionData);
  }, [submissionData]);

  const items = [
    { label: 'Gegevens', slug: 'gegevens' },
    { label: 'Overzicht lijnen', slug: 'lijnen' },
    { label: 'Documenten', slug: 'documenten' },
  ].filter((v) => v) as MenuItem[];

  useEffect(() => {
    setApproved(submission?.status === 'approved');
  }, [submission]);

  useEffect(() => {
    if (isSuccess) {
      toast.success('Aanvraag werd succesvol gewijzigd');
    }
  }, [isSuccess]);

  useEffect(() => {
    if (!isLoading) {
      if (submission) {
        updateSubmissionData(submission);
      }
    }
  }, [isLoading]);

  if (isLoading) {
    return (
      <div className="p-4 h-full">
        <div className="surface-card shadow-2 border-round overflow-hidden h-full">
          <div className="font-medium text-3xl text-900 mb-3 h-full flex align-items-center justify-content-center">
            <ProgressSpinner style={{ maxHeight: 50 }} />
          </div>
        </div>
      </div>
    );
  }

  if (!submission) {
    return <NotFound />;
  }

  const selectedItemIndex = items.findIndex((item) => (item as TabMenuTabChangeParamsWithSlug).slug === location.pathname.split('/').pop());

  return (
    <div className="p-4 h-full">
      <div className="surface-card shadow-2 border-round overflow-hidden h-full flex flex-column">
        <div className="surface-section px-4 py-5">
          <div className="flex flex-column md:align-items-center md:justify-content-between md:flex-row">
            <div className="font-medium text-3xl text-900">
              {submission.description}
            </div>
            <div className="mt-3 md:mt-0">
              <Button label="Sla op" icon="pi pi-check" className="p-button-sm" disabled={approved} onClick={saveAanvraag} />
            </div>
          </div>
        </div>
        <TabMenu
          model={items}
          activeIndex={selectedItemIndex}
          onTabChange={(e) => {
            navigate((e.value as TabMenuTabChangeParamsWithSlug).slug);
          }}
        />
        <div className="p-4 overflow-y-auto flex-grow-1">
          <Outlet context={{ updateSubmissionData, approved }} />
        </div>
      </div>
    </div>
  );
}

export default Submission;
