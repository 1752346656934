/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form } from 'relyus-components';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { Dispatch, ReactElement, SetStateAction } from 'react';
import { FieldValues } from 'react-hook-form';

interface IFormProps {
  control: unknown;
  formState: {
    errors: {
      [key: string]: unknown;
    };
  }
  watch: (callback: Dispatch<SetStateAction<any>>) => void;
}

interface ISideFormProps {
  title: string;
  defaultValues: FieldValues;
  onSubmit: (data?: any) => void;
  visible: boolean;
  setVisible: (bool: boolean) => void;
  children: (formProps: IFormProps) => ReactElement;
}

function SideForm(props: ISideFormProps): JSX.Element {
  const {
    title, children, defaultValues, onSubmit, visible, setVisible,
  } = props;

  return (
    <Sidebar visible={visible} onHide={() => setVisible(false)} position="right">
      <div className="flex flex-column h-full">
        <div className="p-sidebar-custom-header mb-3">
          <div className="flex align-items-start bg-blue-500 justify-content-between p-3">
            <h1 className="text-white text-xl">{title}</h1>
            <Button icon="pi pi-arrow-right" className="p-button-rounded p-button-text p-button-warning p-button-plain text-white p-button-lg" aria-label="Cancel" onClick={() => setVisible(false)} />
          </div>
        </div>
        <Form onSubmit={onSubmit} defaultValues={defaultValues}>
          {(formProps) => (
            <>
              {children(formProps)}
              <Button type="submit" icon="pi pi-check" label="Sla op" className="p-button-success" style={{ width: 'auto' }} />
            </>
          )}
        </Form>
      </div>
    </Sidebar>
  );
}

export default SideForm;
