import { ReactElement, useEffect } from 'react';
import { Button } from 'primereact/button';
import { ControlledInput, Form, Validation } from 'relyus-components';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useLoginMutation } from 'src/services/service';

function LoginForm(): ReactElement {
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const [login, { isSuccess }] = useLoginMutation();

  useEffect(() => {
    async function checkAuthentication(): Promise<void> {
      if (isSuccess) {
        navigate(search.get('redirectUrl') || '/');
      }
    }

    checkAuthentication();
  }, [isSuccess]);

  return (
    <Form onSubmit={({ email, password }) => login({ email, password })} defaultValues={{}}>
      {({ control, formState: { errors } }) => (
        <>
          <ControlledInput
            control={control}
            rules={{
              ...Validation.required('Emailadres is verplicht.'),
              ...Validation.email('Ongeldig emailadres. bv. voorbeeld@email.com'),
            }}
            name="email"
            label="Emailadres"
            autoComplete="username"
            type="email"
            error={errors.email}
          />
          <ControlledInput
            control={control}
            rules={{
              ...Validation.required('Wachtwoord is verplicht.'),
              // ...Validation.length({ min: 8, max: 20 }, 'Wachtwoord moet tussen 8 en 20 tekens lang zijn.'),
              // ...Validation.password({
              //   lowercase: true, uppercase: true, number: true, special: true,
              // }, 'Wachtwoord moet minstens bevatten: kleine letter, hoofdletter, cijfer, speciaal karakter'),
            }}
            name="password"
            label="Wachtwoord"
            autoComplete="password"
            type="password"
            error={errors.password}
          />
          <div className="pt-3">
            <Button type="submit" label="Aanmelden" />
          </div>
        </>
      )}
    </Form>
  );
}

export default LoginForm;
