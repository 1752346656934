import { ColumnEditorOptions } from 'primereact/column';
import { InputNumber } from 'primereact/inputnumber';

function CurrencyEditor(options: ColumnEditorOptions): JSX.Element {
  const { value, editorCallback } = options;

  return (
    <InputNumber
      mode="currency"
      currency="EUR"
      useGrouping={false}
      minFractionDigits={2}
      maxFractionDigits={2}
      value={value}
      onChange={(e) => editorCallback?.(e.value)}
    />
  );
}

export default CurrencyEditor;
