import { useEffect, useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import CategoryModel from 'src/models/category';
import { useCreateCategoryMutation, useGetCategoriesQuery } from 'src/services/service';
import SideForm from 'src/components/SideForm';
import { InputSwitch, InputSwitchProps } from 'primereact/inputswitch';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { ControlledInput, Validation } from 'relyus-components';
import { ControllerRenderProps } from 'react-hook-form';

interface ICreateCategoryProps {
  visible: boolean;
  setVisible: (bool: boolean) => void;
}

function CreateCategory(props: ICreateCategoryProps): JSX.Element {
  const { visible, setVisible } = props;
  const navigate = useNavigate();
  const [create, { data: newCategory, isSuccess: isCreated }] = useCreateCategoryMutation();
  const [category, setCategory] = useState(new CategoryModel());
  const { data: { data: categories } = {} } = useGetCategoriesQuery({ page: 1, size: 999 });

  useEffect(() => {
    if (isCreated) {
      toast.success('Categorie werd succesvol opgeslagen');
      setCategory(new CategoryModel());
      setVisible(false);
      navigate(`/instellingen/categorieen/${newCategory.data?.id}`);
    }
  }, [isCreated]);

  const onSubmit = useCallback(() => {
    create(category);
  }, [category]);

  return (
    <SideForm
      title="Nieuwe categorie"
      onSubmit={onSubmit}
      defaultValues={category}
      visible={visible}
      setVisible={setVisible}
    >
      {({ control, formState: { errors }, watch }) => {
        watch(setCategory);
        return (
          <>
            <ControlledInput
              className="input-group"
              control={control}
              name="isParent"
              label="Categorie is hoofdcategorie"
              render={({ field }: { field: InputSwitchProps }) => (
                <InputSwitch
                  {...field}
                  checked={field.value}
                  onChange={(e) => {
                    if (field.onChange) {
                      field.onChange(e);
                    }
                  }}
                />
              )}
            />
            <ControlledInput
              className="input-group"
              control={control}
              rules={{
                ...Validation.required('Dit veld is verplicht.'),
                ...Validation.length({ min: 3, max: 255 }, 'Gelieve minimaal 3 en maximaal 255 karakters in te vullen'),
              }}
              name="name"
              label="Naam"
              error={errors.name}
            />
            { category.isParent && (
            <>
              <ControlledInput
                className="input-group"
                control={control}
                name="taxAmount"
                label="Voordeel alle aard"
                render={({ field }: { field: ControllerRenderProps }) => (
                  <InputNumber
                    {...field}
                    mode="currency"
                    currency="EUR"
                    showButtons
                    min={0}
                    step={0.01}
                    onChange={(e) => {
                      if (field.onChange) {
                        field.onChange(e.value);
                      }
                    }}
                  />
                )}
              />
              <ControlledInput
                className="input-group"
                control={control}
                rules={{
                  ...Validation.number({ min: 1 }, 'Dit veld is verplicht.'),
                }}
                error={errors.writeOffYears}
                name="writeOffYears"
                label="Afschrijving"
                render={({ field }: { field: ControllerRenderProps }) => (
                  <InputNumber
                    {...field}
                    suffix=" jaar"
                    onChange={(e) => {
                      if (field.onChange) {
                        field.onChange(e.value);
                      }
                    }}
                  />
                )}
              />
            </>
            )}
            { !category.isParent && (
            <ControlledInput
              className="input-group"
              control={control}
              rules={{
                ...Validation.required('Dit veld is verplicht.'),
              }}
              error={errors.parentId}
              name="parentId"
              label="Hoofdcategorie"
              render={({ field }: { field: ControllerRenderProps }) => (
                <Dropdown
                  {...field}
                  placeholder="Selecteer een categorie"
                  optionLabel="name"
                  optionValue="id"
                  options={categories}
                  onChange={(e) => {
                    if (field.onChange) {
                      field.onChange(e);
                    }
                  }}
                />
              )}
            />
            )}
          </>
        );
      }}
    </SideForm>
  );
}

export default CreateCategory;
