import {
  ReactElement, useEffect, useRef, useState,
} from 'react';
import { Outlet, useNavigate, RouteObject } from 'react-router-dom';
import { PanelMenu } from 'primereact/panelmenu';
import { Menu } from 'primereact/menu';
import { useLogoutMutation } from 'src/services/service';
import { Button } from 'primereact/button';
import getRoutes from 'src/routes';
import CookieService from 'src/services/cookie';
import { BreadcrumbComponent } from '../components/Breadcrumb';

function Authenticated(): ReactElement {
  const menu = useRef(null);
  const navigate = useNavigate();
  const [logout, { isSuccess }] = useLogoutMutation();
  const [routes, setRoutes] = useState<RouteObject[]>([]);

  useEffect(() => {
    getRoutes().then((r) => setRoutes(r));
  }, [CookieService().get('token')]);

  useEffect(() => {
    if (isSuccess) {
      navigate('/login');
    }
  }, [isSuccess]);

  return (
    <div className="max-h-screen flex relative lg:static surface-ground">
      <div className="surface-section h-screen hidden block flex-shrink-0 static left-0 top-0 z-1 border-right-1 surface-border select-none" style={{ width: 280 }}>
        <div className="flex flex-column h-full">
          <div className="flex align-items-center px-5 flex-shrink-0" style={{ height: 60 }}>
            <img src={process.env.REACT_APP_LOGO_FILENAME} alt="Logo" height="36" style={{ marginLeft: '-.7em' }} />
          </div>
          <div className="overflow-y-auto">
            <PanelMenu
              model={[
                { icon: 'pi pi-users', label: 'Medewerkers', command: () => navigate('/medewerkers') },
                { icon: 'pi pi-cog', label: 'Instellingen', items: [{ icon: 'pi pi-tags', label: 'Categorieën', command: () => navigate('/instellingen/categorieen') }] },
              ]}
              className="bg-white border-0"
            />
          </div>
        </div>
      </div>
      <div className="min-h-screen flex flex-column relative flex-auto">
        <div
          className="flex justify-content-between align-items-center px-5 surface-0 border-bottom-1 surface-border relative lg:static"
          style={{ minHeight: 60, maxHeight: 60 }}
        >
          <div className="flex">
            <BreadcrumbComponent routes={routes} />
          </div>
          <ul
            className="flex align-items-center list-none"
          >
            <li
              className="p-2 pr-3 border-round border-top-1 surface-border lg:border-top-none flex align-items-center cursor-pointer avatar-container"
              style={{ userSelect: 'none' }}
            >
              <Button type="button" onClick={(e) => menu.current && (menu.current as Menu).toggle(e)} className="p-button-text text-900 p-button-sm">
                <i className="pi pi-user mr-3" />
                <div className="flex flex-column">
                  <span className="font-medium text-sm">Test</span>
                </div>
              </Button>
              <Menu
                ref={menu}
                popup
                model={[
                  {
                    label: 'Profiel',
                    icon: 'pi pi-fw pi-user',
                    url: '/profiel',
                  },
                  {
                    label: 'Afmelden',
                    icon: 'pi pi-power-off',
                    command: logout,
                  },
                ]}
                className="avatar-menu"
              />
            </li>
          </ul>
        </div>
        <div className="overflow-y-auto flex flex-column flex-auto">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default Authenticated;
