import { createAction, createReducer } from '@reduxjs/toolkit';
import SubmissionModel from 'src/models/submission';

const set = createAction<SubmissionModel>('submissions/set');

export interface SubmissionsState {
  data: SubmissionModel
}

const initialState = { data: new SubmissionModel() } as SubmissionsState;

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(set, (state, action) => {
      const newState = state;
      newState.data = action.payload;
    });
});

export const submissions = {
  reducerPath: 'submissions',
  reducer,
};
