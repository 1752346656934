import { Button } from 'primereact/button';
import { useNavigate } from 'react-router';

export default function NotFound(): JSX.Element {
  const navigate = useNavigate();

  return (
    <div className="h-full px-4 py-8 md:px-6 lg:px-8">
      <div style={{ background: 'radial-gradient(50% 109137.91% at 50% 50%, rgba(233, 30, 99, 0.1) 0%, rgba(254, 244, 247, 0) 100%);' }} className="text-center">
        <span className="text-blue-500 font-bold text-4xl inline-block px-3">404</span>
      </div>
      <div className="mt-6 mb-5 font-bold text-6xl text-900 text-center">Pagina niet gevonden</div>
      <p className="text-700 text-3xl mt-0 mb-6 text-center">Sorry, we konden de pagina niet vinden.</p>
      <div className="text-center">
        <Button className="p-button-text mr-2" label="Ga terug" icon="pi pi-arrow-left" onClick={() => navigate(-1)} />
        <Button label="Ga naar startscherm" icon="pi pi-home" onClick={() => navigate('/')} />
      </div>
    </div>
  );
}
