import { useGetTotalSpentPerCategoryQuery } from 'src/services/service';
import { Chart } from 'primereact/chart';
import { useEffect, useState } from 'react';
import randomColor from 'randomcolor';

interface Dataset {
    type: string;
    label: string;
    backgroundColor: string;
    data: number[];
}

function Dashboard(): JSX.Element {
  const [chartData, setChartData] = useState({
    labels: [] as string[],
    datasets: [] as Dataset[],
  });
  const { data: { data = [] } = {} } = useGetTotalSpentPerCategoryQuery(1);

  useEffect(() => {
    if (data.length) {
      setChartData(data.reduce((acc, item) => {
        const { category, totalAmount } = item;
        acc.datasets.push({
          type: 'bar', label: category.name, backgroundColor: randomColor(), data: [totalAmount],
        });
        return acc;
      }, {
        labels: ['Totaal uitgegeven'] as string[],
        datasets: [] as Dataset[],
      }));
    }
  }, [data]);

  return (
    <div className="p-4 flex">
      <div className="p-4 surface-section border-round overflow-hidden shadow-1 flex-shrink-1" style={{ width: '50%' }}>
        <Chart
          type="bar"
          data={chartData}
          options={{
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            plugins: {
              legend: {
                labels: {
                  color: '#495057',
                },
              },
            },
            scales: {
              x: {
                ticks: {
                  color: '#495057',
                },
                grid: {
                  color: '#ebedef',
                },
              },
              y: {
                ticks: {
                  color: '#495057',
                },
                grid: {
                  color: '#ebedef',
                },
              },
            },
          }}
        />
      </div>
    </div>
  );
}

export default Dashboard;
