import { useEffect, useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import EmployeeModel from 'src/models/employee';
import { useCreateEmployeeMutation } from 'src/services/service';
import SideForm from 'src/components/SideForm';
import { InputNumber } from 'primereact/inputnumber';
import { ControlledInput, Validation } from 'relyus-components';
import { ControllerRenderProps } from 'react-hook-form';
import moment from 'moment';
import { Calendar, CalendarProps } from 'primereact/calendar';

interface ICreateEmployeeProps {
  visible: boolean;
  setVisible: (bool: boolean) => void;
}

function CreateEmployee(props: ICreateEmployeeProps): JSX.Element {
  const { visible, setVisible } = props;
  const navigate = useNavigate();
  const [create, { data: newEmployee, isSuccess: isCreated }] = useCreateEmployeeMutation();
  const [employee, setEmployee] = useState(new EmployeeModel());

  useEffect(() => {
    if (isCreated) {
      toast.success('Medewerker werd succesvol opgeslagen');
      setEmployee(new EmployeeModel());
      setVisible(false);
      navigate(`/medewerkers/${newEmployee.data?.id}/gegevens`);
    }
  }, [isCreated]);

  const onSubmit = useCallback(() => {
    create(employee);
  }, [employee]);

  return (
    <SideForm
      title="Nieuwe medewerker"
      onSubmit={onSubmit}
      defaultValues={employee}
      visible={visible}
      setVisible={setVisible}
    >
      {({ control, formState: { errors }, watch }) => {
        watch(setEmployee);
        return (
          <>
            <ControlledInput
              className="input-group"
              control={control}
              rules={{
                ...Validation.required('Dit veld is verplicht.'),
                ...Validation.length({ min: 3, max: 255 }, 'Gelieve minimaal 3 en maximaal 255 karakters in te vullen'),
              }}
              name="firstName"
              label="Voornaam"
              error={errors.firstName}
            />
            <ControlledInput
              className="input-group"
              control={control}
              rules={{
                ...Validation.required('Dit veld is verplicht.'),
                ...Validation.length({ min: 3, max: 255 }, 'Gelieve minimaal 3 en maximaal 255 karakters in te vullen'),
              }}
              name="lastName"
              label="Achternaam"
              error={errors.lastName}
            />
            <ControlledInput
              className="input-group"
              control={control}
              rules={{
                ...Validation.required('Dit veld is verplicht.'),
                ...Validation.email('Gelieve een geldig mailadres in te vullen. (bv. xxx@yyy.zz)'),
                ...Validation.length({ min: 3, max: 255 }, 'Gelieve minimaal 3 en maximaal 255 karakters in te vullen'),
              }}
              name="email"
              label="E-mailadres"
              error={errors.email}
            />
            <ControlledInput
              className="input-group"
              control={control}
              rules={{
                ...Validation.required('Dit veld is verplicht.'),
                ...Validation.length({ min: 3, max: 255 }, 'Gelieve minimaal 3 en maximaal 255 karakters in te vullen'),
              }}
              name="employeeNumber"
              label="Personeelsnummer"
              error={errors.employeeNumber}
            />
            <ControlledInput
              className="input-group"
              control={control}
              rules={{
                ...Validation.required('Dit veld is verplicht.'),
              }}
              error={errors.startedAt}
              name="startedAt"
              label="In dienst sinds"
              render={({ field }: { field: CalendarProps }) => (
                <Calendar
                  {...field}
                  value={moment(field.value as Date).toDate()}
                  showIcon
                  dateFormat="dd/mm/yy"
                  onChange={(e) => {
                    if (field.onChange) {
                      field.onChange(e);
                    }
                  }}
                />
              )}
            />
            <ControlledInput
              className="input-group"
              control={control}
              name="remainingWorkDays"
              label="Resterende werkdagen"
              render={({ field }: { field: ControllerRenderProps }) => (
                <InputNumber
                  {...field}
                  value={field.value || 0}
                  showButtons
                  min={0}
                  step={1}
                  onChange={(e) => {
                    if (field.onChange) {
                      field.onChange(e.value);
                    }
                  }}
                />
              )}
            />
            <ControlledInput
              className="input-group"
              control={control}
              name="salary"
              label="Loon"
              render={({ field }: { field: ControllerRenderProps }) => (
                <InputNumber
                  {...field}
                  value={field.value || 0}
                  mode="currency"
                  currency="EUR"
                  useGrouping={false}
                  minFractionDigits={2}
                  maxFractionDigits={2}
                  onChange={(e) => {
                    if (field.onChange) {
                      field.onChange(e.value);
                    }
                  }}
                />
              )}
            />
          </>
        );
      }}
    </SideForm>
  );
}

export default CreateEmployee;
