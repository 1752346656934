import { ProgressSpinner } from 'primereact/progressspinner';
import { ControllerRenderProps, FieldValues } from 'react-hook-form';
import { useParams } from 'react-router';
import { ControlledInput, Form, Validation } from 'relyus-components';
import { useGetEmployeeQuery } from 'src/services/service';
import { Calendar, CalendarProps } from 'primereact/calendar';
import moment from 'moment';
import { useOutletContext } from 'react-router-dom';
import { IEmployeeOutletContext } from 'src/views/Employees/Detail';
import { InputNumber } from 'primereact/inputnumber';

function EmployeeInfo(): JSX.Element {
  const { id } = useParams();
  const { data: { data: user } = {}, isLoading } = useGetEmployeeQuery(id);
  const outlet: IEmployeeOutletContext = useOutletContext();

  if (isLoading) {
    return (
      <div className="h-full flex-grow-1">
        <div className="font-medium text-3xl text-900 mb-3 h-full flex align-items-center justify-content-center">
          <ProgressSpinner style={{ maxHeight: 50 }} />
        </div>
      </div>
    );
  }

  return (
    <Form defaultValues={user as FieldValues}>
      {({ control, formState: { errors }, watch }) => {
        watch(outlet.updateUserData);
        return (
          <>
            <ControlledInput
              className="input-group"
              control={control}
              rules={{
                ...Validation.required('Dit veld is verplicht.'),
                ...Validation.length({ min: 3, max: 255 }, 'Gelieve minimaal 3 en maximaal 255 karakters in te vullen'),
              }}
              name="firstName"
              label="Voornaam"
              error={errors.firstName}
            />
            <ControlledInput
              className="input-group"
              control={control}
              rules={{
                ...Validation.required('Dit veld is verplicht.'),
                ...Validation.length({ min: 3, max: 255 }, 'Gelieve minimaal 3 en maximaal 255 karakters in te vullen'),
              }}
              name="lastName"
              label="Achternaam"
              error={errors.lastName}
            />
            <ControlledInput
              className="input-group"
              control={control}
              rules={{
                ...Validation.email('Gelieve een geldig mailadres in te vullen. (bv. xxx@yyy.zz)'),
                ...Validation.length({ min: 3, max: 255 }, 'Gelieve minimaal 3 en maximaal 255 karakters in te vullen'),
              }}
              name="email"
              label="E-mailadres"
              error={errors.email}
            />
            <ControlledInput
              className="input-group"
              control={control}
              rules={{
                ...Validation.required('Dit veld is verplicht.'),
                ...Validation.length({ min: 3, max: 255 }, 'Gelieve minimaal 3 en maximaal 255 karakters in te vullen'),
              }}
              name="employeeNumber"
              label="Personeelsnummer"
              error={errors.employeeNumber}
            />
            <ControlledInput
              className="input-group"
              control={control}
              rules={{
                ...Validation.required('Dit veld is verplicht.'),
              }}
              error={errors.startedAt}
              name="startedAt"
              label="In dienst sinds"
              render={({ field }: { field: CalendarProps }) => (
                <Calendar
                  {...field}
                  value={moment(field.value as Date).toDate()}
                  showIcon
                  dateFormat="dd/mm/yy"
                  onChange={(e) => {
                    if (field.onChange) {
                      field.onChange(e);
                    }
                  }}
                />
              )}
            />
            <ControlledInput
              className="input-group"
              control={control}
              name="endedAt"
              label="Uitdiensttreding"
              render={({ field }: { field: CalendarProps }) => (
                <Calendar
                  {...field}
                  value={moment(field.value as Date).toDate()}
                  showIcon
                  dateFormat="dd/mm/yy"
                  onChange={(e) => {
                    if (field.onChange) {
                      field.onChange(e);
                    }
                  }}
                />
              )}
            />
            <ControlledInput
              className="input-group"
              control={control}
              name="remainingWorkDays"
              label="Resterende werkdagen"
              render={({ field }: { field: ControllerRenderProps }) => (
                <InputNumber
                  {...field}
                  value={field.value || 0}
                  showButtons
                  min={0}
                  step={1}
                  onChange={(e) => {
                    if (field.onChange) {
                      field.onChange(e.value);
                    }
                  }}
                />
              )}
            />
            <ControlledInput
              className="input-group"
              control={control}
              name="extraVacationDays"
              label="Niet gelijkgestelde dagen"
              render={({ field }: { field: ControllerRenderProps }) => (
                <InputNumber
                  {...field}
                  value={field.value || 0}
                  showButtons
                  min={0}
                  step={1}
                  onChange={(e) => {
                    if (field.onChange) {
                      field.onChange(e.value);
                    }
                  }}
                />
              )}
            />
          </>
        );
      }}
    </Form>
  );
}

export default EmployeeInfo;
