import { useCreateFileForSubmissionMutation, useDeleteFileMutation, useGetSubmissionQuery } from 'src/services/service';
import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router';
import { DataGrid } from 'relyus-components';
import { FileUpload, FileUploadHandlerParam } from 'primereact/fileupload';
import { toast } from 'react-toastify';
import { Button } from 'primereact/button';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { getFile, IFile } from 'src/services/fileService';

const Header = ({ id }: { id: string }): JSX.Element => {
  const [create, { isSuccess }] = useCreateFileForSubmissionMutation();

  useEffect(() => {
    if (isSuccess) {
      toast.success('Document werd geüpload');
    }
  }, [isSuccess]);

  return (
    <div className="flex justify-content-between align-items-center">
      <h2 className="m-0 w-full">Documenten</h2>
      <FileUpload
        className="w-11rem"
        customUpload
        chooseLabel="Kies bestand"
        mode="basic"
        name="file[]"
        accept="*/*"
        auto
        maxFileSize={10000000}
        uploadHandler={async (e: FileUploadHandlerParam) => {
          const { files, options: { clear } } = e;

          const formData = new FormData();

          if (files.length > 0) {
            files.forEach((file) => formData.append('file', file));
          }

          // Call the Upload API
          create({ id, data: formData }).then(clear);
        }}
      />
    </div>
  );
};

function Files(): JSX.Element|null {
  const { submissionId } = useParams();

  const { data: { data: submission } = {}, isLoading } = useGetSubmissionQuery(submissionId);
  const [remove] = useDeleteFileMutation();

  const renderHeader = useCallback(() => <Header id={submissionId || ''} />, [submissionId]);

  const Delete = useCallback((rowData: IFile) => (
    <>
      <Button
        className="p-button-text p-button-info"
        icon="pi pi-download"
        onClick={() => {
          getFile(rowData);
        }}
      />
      <Button
        className="p-button-text p-button-danger"
        icon="pi pi-trash"
        onClick={(e) => {
          confirmPopup({
            target: e.currentTarget,
            message: 'Bent u zeker dat u deze lijn wilt verwijderen?',
            icon: 'pi pi-exclamation-triangle',
            accept: () => remove(rowData.id),
          });
        }}
      />
      <ConfirmPopup />
    </>
  ), []);

  return (
    <div className="h-full flex-grow-1 surface-border border-1 border-bottom-none border-round overflow-hidden">
      <DataGrid
        header={renderHeader}
        emptyMessage="Geen documenten gevonden"
        scrollHeight="400px"
        scrollDirection="vertical"
        scrollable
        lazy
        size="normal"
        value={submission?.files || []}
        loading={isLoading}
        columns={[
          {
            columnKey: 'originalFileName', field: 'originalFileName', header: 'Naam', style: { minWidth: '90%' },
          },
          {
            columnKey: 'delete', header: 'Acties', body: Delete, style: { width: '5%' },
          },
        ]}
      />
    </div>
  );
}

export default Files;
