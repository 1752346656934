import { BreadCrumb } from 'primereact/breadcrumb';
import { useEffect, useState } from 'react';
import { useLocation, RouteObject, matchRoutes } from 'react-router';

interface IMenuItem {
  icon?: string;
  label?: string;
  url: string;
}

interface IProps {
  routes: RouteObject[];
}

export function BreadcrumbComponent(props: IProps): JSX.Element {
  const { routes } = props;
  const [crumbs, setCrumbs] = useState<IMenuItem[]>([]);
  const location = useLocation();
  const home = { icon: 'pi pi-home', url: '/' };

  useEffect(() => {
    if (routes.length > 0) {
      const matches = matchRoutes(routes, location.pathname);
      matches?.shift(); // remove home route;
      setCrumbs(
        matches?.map((match) => ({ label: match?.route.id, url: match.pathname })) as [],
      );
    }
  }, [location, routes]);

  return (
    <BreadCrumb model={crumbs} home={home} />
  );
}
