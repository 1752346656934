import { combineReducers } from '@reduxjs/toolkit';
import { service } from '../services/service';
import { submissions } from '../services/submissions';
import { employees } from '../services/employees';

const rootReducer = combineReducers({
  [service.reducerPath]: service.reducer,
  [submissions.reducerPath]: submissions.reducer,
  [employees.reducerPath]: employees.reducer,
});

export default rootReducer;
