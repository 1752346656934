import { RouteObject } from 'react-router-dom';
import Authenticated from 'src/layouts/Authenticated';
import Unauthenticated from 'src/layouts/Unauthenticated';
import CookieService from 'src/services/cookie';
import Employee from 'src/views/Employees/Detail';
import EmployeeInfo from 'src/views/Employees/views/Info';
import EmployeeSalaries from 'src/views/Employees/views/Salaries/Overview';
import EmployeeSubmissions from 'src/views/Employees/views/Submissions/Overview';
import Employees from 'src/views/Employees/Overview';
import Category from 'src/views/Settings/views/Categories/Detail';
import Categories from 'src/views/Settings/views/Categories/Overview';
import Settings from 'src/views/Settings/Page';
import Login from 'src/views/Login';
import NotFound from 'src/views/NotFound';
import Submission from 'src/views/Submission/Detail';
import SubmissionInfo from 'src/views/Submission/Info';
import SubmissionItems from 'src/views/Submission/Items';
import SubmissionFiles from 'src/views/Submission/Files';
import Dashboard from 'src/views/Dashboard';

async function getRoutes(): Promise<RouteObject[]> {
  const authenticated = !!CookieService().get('token');

  return [
    authenticated && {
      path: '/',
      element: <Authenticated />,
      children: [
        {
          path: '',
          element: <Dashboard />,
        },
        {
          path: 'medewerkers',
          id: 'Medewerkers',
          element: <Employees />,
          children: [
            {
              path: ':id',
              element: <Employee />,
              id: 'Medewerker',
              children: [
                {
                  path: 'gegevens',
                  id: 'Gegevens',
                  element: <EmployeeInfo />,
                },
                {
                  path: 'lonen',
                  id: 'Lonen',
                  element: <EmployeeSalaries />,
                },
                {
                  path: 'aanvragen/:submissionId',
                  element: <Submission />,
                  id: 'Aanvraag',
                  children: [
                    {
                      path: 'gegevens',
                      id: 'Gegevens',
                      element: <SubmissionInfo />,
                    },
                    {
                      path: 'lijnen',
                      id: 'Lijnen',
                      element: <SubmissionItems />,
                    },
                    {
                      path: 'documenten',
                      id: 'Documenten',
                      element: <SubmissionFiles />,
                    },
                  ],
                },
                {
                  path: 'aanvragen',
                  id: 'Aanvragen',
                  element: <EmployeeSubmissions />,
                },
              ],
            },
          ],
        },
        {
          path: 'instellingen',
          id: 'Instellingen',
          element: <Settings />,
          children: [
            {
              path: 'categorieen',
              id: 'Categorieën',
              element: <Categories />,
              children: [
                {
                  path: ':id',
                  id: 'Categorie',
                  element: <Category />,
                },
              ],
            },
          ],
        },
        {
          path: '*',
          element: <NotFound />,
        },
      ],
    },
    {
      path: '/',
      element: <Unauthenticated />,
      children: [
        {
          path: 'login',
          element: <Login />,
        },
      ],
    },
  ].filter((v) => v) as RouteObject[];
}

export default getRoutes;
