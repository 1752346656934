import {
  Outlet, useLocation, useNavigate,
} from 'react-router';
import { TabMenu, TabMenuTabChangeParams } from 'primereact/tabmenu';
import { Button } from 'primereact/button';
import { useState } from 'react';
import CreateCategory from './views/Categories/Create';

interface TabMenuTabChangeParamsWithSlug extends TabMenuTabChangeParams {
  slug: string,
}

const items = [
  { label: 'Categorieën', slug: 'categorieen' },
];

function Settings(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const [showCreate, setShowCreate] = useState(false);

  const selectedItemIndex = items.findIndex((item) => item.slug === location.pathname.split('/').pop());

  return (
    <>
      <div className="p-4 h-full">
        <div className="surface-card shadow-2 border-round overflow-hidden h-full flex flex-column">
          <div className="surface-section px-4 py-5">
            <div className="flex flex-column md:align-items-center md:justify-content-between md:flex-row">
              <div className="font-medium text-3xl text-900">
                Instellingen
              </div>
              {location.pathname.endsWith('categorieen') && (
                <Button
                  icon="pi pi-plus"
                  label="Maak categorie"
                  className="p-button-success p-button-sm"
                  onClick={() => setShowCreate(true)}
                />
              )}
            </div>
          </div>
          <TabMenu
            model={items}
            activeIndex={selectedItemIndex}
            onTabChange={(e) => {
              navigate((e.value as TabMenuTabChangeParamsWithSlug).slug);
            }}
          />
          <div className="p-4 overflow-y-auto flex-grow-1">
            <Outlet />
          </div>
        </div>
      </div>
      <CreateCategory visible={showCreate} setVisible={setShowCreate} />
    </>
  );
}

export default Settings;
